import ErrorCode from "@common/kernel/error-code";
import MessageRepository from "@domain/repositories/message-repository";

import { DEFAULT_TIMEOUT } from "@common/constant";
import {
  CreateMessageBody,
  EditMessageResponse,
  GetCountUnreadMessageResponse,
  GetMessageDetailResponse,
  GetMessageListQuery,
  GetMessageListResponse,
  GetPinMessageResponse,
  IMention,
  PinMessageBody,
  PinMessageResponse,
  ReactionMessageBody,
  ReactionMessageResponse,
  SearchMessageQuery,
  SearchMessageResponse,
  UnPinMessageBody
} from "@domain/interfaces/message-interface";
import { SocketResponse } from "@domain/interfaces/socket-interface";
import { socket } from "@infrastructure/network/socket";
import { injectable } from "inversify";

@injectable()
export default class MessageApiRepository implements MessageRepository {
  getList (query: GetMessageListQuery): Promise<GetMessageListResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:getList", query, (error, data: GetMessageListResponse) => {
        if (error) {
          resolve(
            new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {
              internal: 0,
              customerCare: 0,
            })
          );
        }
        resolve(data);
      });
    });
  }

  create (body: CreateMessageBody): Promise<GetMessageDetailResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:create", body, (error, data: GetMessageDetailResponse) => {
        if (error) {
          resolve(
            new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {
              internal: 0,
              customerCare: 0,
            })
          );
        }
        resolve(data);
      });
    });
  }

  remove (id: string): Promise<GetMessageDetailResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:remove", id, (error, data: GetMessageDetailResponse) => {
        if (error) {
          resolve(
            new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {
              internal: 0,
              customerCare: 0,
            })
          );
        }
        resolve(data);
      });
    });
  }

  search (query: SearchMessageQuery): Promise<SearchMessageResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:search", query, (error, data: SearchMessageResponse) => {
        if (error) {
          resolve(
            new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {
              internal: 0,
              customerCare: 0,
            })
          );
        }
        resolve(data);
      });
    });
  }

  getCountUnreadMessage (): Promise<GetCountUnreadMessageResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:totalUnreadCount", {}, (error, data: GetCountUnreadMessageResponse) => {
        if (error) {
          resolve(
            new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {
              internal: 0,
              customerCare: 0,
            })
          );
        }
        resolve(data);
      });
    });
  }

  editMessage (id: string, body: { content: string; mention: IMention[] }): Promise<EditMessageResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:edit", id, body, (error, data: EditMessageResponse) => {
        if (error) {
          resolve(new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {}));
        }
        resolve(data);
      });
    });
  }

  reactionMessage (body: ReactionMessageBody): Promise<ReactionMessageResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:reaction", body, (error, data: ReactionMessageResponse) => {
        if (error) {
          resolve(new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {}));
        }
        resolve(data);
      });
    });
  }

  getPinMessage (conversationId: string): Promise<GetPinMessageResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:getPinList", { conversationId }, (error, data: GetPinMessageResponse) => {
        if (error) {
          resolve(new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {}));
        }
        resolve(data);
      });
    });
  }

  pinMessage (body: PinMessageBody): Promise<PinMessageResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:pin", body, (error, data) => {
        if (error) {
          resolve(new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {}));
        }
        resolve(data);
      });
    });
  }

  unpinMessage (body: UnPinMessageBody): Promise<PinMessageResponse> {
    return new Promise((resolve) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("message:unpin", body, (error, data) => {
        if (error) {
          resolve(new SocketResponse(ErrorCode.WEB_SYSTEM_ERROR.code, ErrorCode.WEB_SYSTEM_ERROR.code, error.message, {}));
        }
        resolve(data);
      });
    });
  }
}
