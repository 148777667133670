import { scrollToMessage } from "@domain/helpers/message-helper";
import { Chat } from "@domain/interfaces/conversation-interface";
import { IMessage, MessageActions } from "@domain/interfaces/message-interface";
import { defineStore } from "pinia";
import { useConversationStore } from "./conversation-store";

export const useAppStateStore = defineStore("app-state", {
  state: () => ({
    userChatModal: {
      visible: false,
      action: "" as "addMember" | "forward" | "",
      selectedList: [] as Chat[],
      list: [] as Chat[],
      fetching: false,
    },
    reactionMessageModalVisible: false,
    messageAction: "" as MessageActions | undefined,
    selectedMessage: {} as IMessage,
    quoteText: "",
    rightSidebar: {
      visible: false,
      slot: undefined as "groupInfo" | "userInfo" | "setting" | undefined,
    },
    chatText: "",
    chatDialogSlot: "chat" as "chat" | "pinMessage",
  }),
  actions: {
    resetState () {
      useAppStateStore().$reset();
    },
    setSelectedMessage (message?: IMessage) {
      this.selectedMessage = message || ({} as IMessage);
    },
    setQuoteText (quoteText: string) {
      this.quoteText = quoteText;
    },
    openChatUserModal (action: "forward" | "addMember") {
      this.userChatModal = {
        visible: true,
        action,
        selectedList: [],
        list: useConversationStore().data.internal.items.map((item) => ({
          ...item,
          type: "chat",
          username: "",
        })),
        fetching: false,
      };
    },
    setChatUserList (list: Chat[]) {
      this.userChatModal.list = list;
    },
    closeChatUserModal () {
      this.userChatModal = {
        visible: false,
        action: "",
        selectedList: [],
        list: [],
        fetching: false,
      };
    },
    closeMessageAction () {
      this.messageAction = undefined;
      this.quoteText = "";
      this.selectedMessage = {} as IMessage;
      this.chatText = "";
    },
    openMessageAction (action: MessageActions) {
      this.messageAction = action;
    },
    openReactionMessageModal () {
      this.reactionMessageModalVisible = true;
    },
    closeReactionMessageModal () {
      this.reactionMessageModalVisible = false;
    },
    openRightSidebar (slot: "groupInfo" | "userInfo" | "setting") {
      this.rightSidebar.slot = slot;
      this.rightSidebar.visible = true;
    },
    closeRightSidebar () {
      this.rightSidebar.slot = undefined;
      this.rightSidebar.visible = false;
    },
    setChatText (text: string) {
      this.chatText = text;
    },
    resetChatText () {
      this.chatText = "";
    },
    focusChatInput () {
      document.getElementById("chat-input")?.focus();
    },
    openPinMessage () {
      this.chatDialogSlot = "pinMessage";
    },
    closePinMessage () {
      this.chatDialogSlot = "chat";
      scrollToMessage(useConversationStore().selectedConversation.conversationDetails?.lastSeenMessageId);
    },
  },
});
