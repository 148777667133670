import Conversation from "@domain/entities/conversation-entity";
import addConversationMemberUseCase from "@domain/use-cases/conversation/add-members";
import createConversationUseCase from "@domain/use-cases/conversation/create-conversation";
import getConversationByIdUseCase from "@domain/use-cases/conversation/get-conversation-by-id";
import getConversationUsernameUseCase from "@domain/use-cases/conversation/get-conversation-by-username";
import getConversationListUseCase from "@domain/use-cases/conversation/get-conversation-list";
import getConversationFileListUseCase from "@domain/use-cases/conversation/get-file-list";
import getConversationMediaListUseCase from "@domain/use-cases/conversation/get-media-list";
import leaveConversationUseCase from "@domain/use-cases/conversation/leave-conversation";
import removeMemberUseCase from "@domain/use-cases/conversation/remove-member";
import updateConversationNameUseCase from "@domain/use-cases/conversation/update-conversation-name";
import updateConversationDetailsUseCase from "@domain/use-cases/conversation/update-details";

import { scrollToEndConversation } from "@domain/helpers/message-helper";
import { UpdateConversationDetailsBody } from "@domain/interfaces/conversation-details-interface";
import { ConversationMedia, ConversationType, GetConversationDetailResponse, GetUserConversationQuery } from "@domain/interfaces/conversation-interface";
import { IMessage } from "@domain/interfaces/message-interface";
import { IUser } from "@domain/interfaces/user-interface";
import getSaveMessageConversationUseCase from "@domain/use-cases/conversation/get-save-message-conversation";
import { SelectProps } from "ant-design-vue";
import { defineStore } from "pinia";
import { useAccountStore } from "./account-store";
import { useAppStateStore } from "./app-state-store";
import { useMessageStore } from "./message-store";

export const useConversationStore = defineStore("conversation", {
  state: () => ({
    data: {
      internal: {
        items: [] as Conversation[],
        total: 0,
        limit: 10,
        fetching: false,
        isMore: true,
        moreFetching: false,
      },
      customerCare: {
        items: [] as Conversation[],
        total: 0,
        limit: 10,
        fetching: false,
        isMore: true,
      },
    },
    tabs: [
      { key: "internal" as ConversationType, title: "Nội bộ", count: 0 },
      { key: "customerCare" as ConversationType, title: "Khách hàng", count: 0 },
    ],
    activeTab: "internal" as ConversationType,
    activeConversationMedia: {
      items: [] as ConversationMedia[],
      isCall: false,
    },
    activeConversationFiles: {
      items: [] as ConversationMedia[],
      isCall: false,
    },
    searchQuery: <GetUserConversationQuery>{},
    alerts: [] as string[],
    selectedConversation: {} as Conversation,
    cacheConversationList: [] as Conversation[],
  }),
  getters: {
    selectedConversationMentionList: (state) => {
      if (!state.selectedConversation.id || !state.selectedConversation.isGroup) return [];

      return state.selectedConversation.membersInfo
        .filter((item) => item.username !== useAccountStore().loginUser.username)
        .map((item) => ({
          label: item.name,
          value: item.username,
          avatar: item.avatar,
        })) as SelectProps["options"];
    },
    conversation: (state) => {
      if (state.activeTab === "customerCare") return state.data.customerCare;

      if (state.activeTab === "internal") return state.data.internal;
    },
  },
  actions: {
    async getConversationDetail (query: { id?: string; username?: string; messageCreatedAt?: Date }) {
      if (query?.id === this.selectedConversation.id) return;

      let result: GetConversationDetailResponse = {
        data: {} as Conversation,
        status: 0,
        code: 0,
        message: "",
      };

      if (query.id) {
        result = await getConversationByIdUseCase(query.id);
      } else if (query.username) {
        result = await getConversationUsernameUseCase(query.username);
      }

      if (result.code !== 1) return;

      if (!this.cacheConversationList.find((item) => item.id === result.data.id)) {
        this.cacheConversationList.push(result.data);
      }
      this.setSelectConversation(result.data);
    },
    async createConversation (username: string) {
      const result = await createConversationUseCase({ members: [username, useAccountStore().loginUser.username], isGroup: false });

      if (result.code === 1) {
        this.data.internal.items.unshift(result.data.conversation);
        this.setSelectConversation(result.data.conversation);
      }
    },
    async getSaveMessageConversation () {
      const result = await getSaveMessageConversationUseCase();

      if (result.code === 1) {
        this.setSelectConversation(result.data.conversation);
      }
    },
    async getConversationMedia () {
      if (this.activeConversationMedia.isCall) {
        return;
      }

      const mediaResult = await getConversationMediaListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtAfter: this.activeConversationMedia.items.length > 0 ? this.activeConversationMedia.items[0].createdAt : undefined,
      });

      if (mediaResult.code === 1) {
        this.activeConversationMedia = {
          items: mediaResult.data.items,
          isCall: true,
        };
      }
    },
    async getConversationFile () {
      if (this.activeConversationFiles.isCall) {
        return;
      }

      const fileResult = await getConversationFileListUseCase({
        conversationId: this.selectedConversation.id,
        createdAtAfter: this.activeConversationFiles.items.length > 0 ? this.activeConversationFiles.items[0].createdAt : undefined,
      });

      if (fileResult.code === 1) {
        this.activeConversationFiles = { items: fileResult.data.items, isCall: true };
      }
    },
    updateConversationDetails (data: UpdateConversationDetailsBody) {
      // console.log(new Date(data.lastSeenAt).toLocaleString(), new Date(this.selectedConversation.conversationDetails.lastSeenAt).toLocaleString());
      // console.log(data);

      if (!data.lastSeenMessageId || !data.lastSeenAt) {
        return;
      }

      if (!this.selectedConversation.conversationDetails.lastSeenAt || data.lastSeenAt >= this.selectedConversation.conversationDetails.lastSeenAt) {
        this.selectedConversation.conversationDetails.lastSeenAt = data.lastSeenAt;
        this.selectedConversation.conversationDetails.lastSeenMessageId = data.lastSeenMessageId;
        updateConversationDetailsUseCase(data);
      }
    },
    async getConversationList () {
      this.data.customerCare.fetching = true;
      // this.searchQuery = query;
      const accountStore = useAccountStore();
      this.data.internal.fetching = true;

      const result = await getConversationListUseCase({
        type: "internal",
        takeCareGroupId: accountStore.loginUser.takeCareGroupId,
      });

      if (result.code !== 1) return;

      this.data.internal = {
        items: result.data.items,
        total: result.data.total,
        limit: result.data.limit,
        fetching: false,
        moreFetching: false,
        isMore: result.data.items.length === result.data.limit,
      };
      this.cacheConversationList = this.data.internal.items;
      this.tabs[0].count = result.data.total;
    },
    async getMoreConversation () {
      if (this.data.internal.isMore) {
        this.data.internal.moreFetching = true;
        const result = await getConversationListUseCase({
          type: "internal",
          updatedAt: this.data.internal.items[this.data.internal.items.length - 1].updatedAt,
          takeCareGroupId: "",
        });

        if (result.code !== 1) return;

        this.data.internal.moreFetching = false;
        this.data.internal.isMore = result.data.items.length === result.data.limit;
        this.data.internal.items = this.data.internal.items.concat(result.data.items);
        this.cacheConversationList = this.data.internal.items.concat(result.data.items);
      }
    },
    async removeMember (member: IUser) {
      const result = await removeMemberUseCase({ id: this.selectedConversation.id, members: [member.username] });

      if (result.code !== 1) return;
      this.selectedConversation.membersInfo = this.selectedConversation.membersInfo.filter((item) => item.username !== member.username);
      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async renameGroup () {
      const result = await updateConversationNameUseCase({ id: this.selectedConversation.id, name: this.selectedConversation.name });

      if (result.code !== 1) return;

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async addMember (members: string[]) {
      const result = await addConversationMemberUseCase({ id: this.selectedConversation.id, members });

      if (result.code !== 1) return;

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    async leaveGroup () {
      const result = await leaveConversationUseCase({ id: this.selectedConversation.id });

      if (result.code !== 1) return;

      this.selectedConversation = {} as Conversation;

      this.alerts = this.alerts.concat(result.data.alerts);
      scrollToEndConversation();
    },
    // local

    setSelectConversation (conversation: Conversation, messageCreatedAt?: Date) {
      // console.log(id);
      const messageStore = useMessageStore();
      const appStateStore = useAppStateStore();

      this.selectedConversation = conversation;
      this.activeConversationMedia = { items: [], isCall: false };
      this.activeConversationFiles = { items: [], isCall: false };

      appStateStore.resetState();
      messageStore.getMessageList({ conversationId: conversation.id, createdAt: messageCreatedAt });
      messageStore.getPinMessage(conversation.id);
    },
    updateLocalSelectedConversation (data: { unreadCount?: number; lastMessageInfo?: IMessage }) {
      if (data.unreadCount !== undefined) {
        const activeConversationIndex = this.data.internal.items.findIndex((item) => item.id === this.selectedConversation.id);
        let unreadCount = data.unreadCount;

        if (unreadCount <= 0) {
          unreadCount = 0;
        }
        this.selectedConversation.unreadCount = unreadCount;

        this.data.internal.items[activeConversationIndex].unreadCount = unreadCount;
      }

      if (data.lastMessageInfo) {
        this.selectedConversation.lastMessageInfo = data.lastMessageInfo;
      }
    },
    updateLocalConversationList (conversation: Conversation, message?: IMessage) {
      const conversationIndex = this.data.internal.items.findIndex((item) => item.id === conversation.id);

      if (conversationIndex === -1) return;

      // update conversation last message, unread count
      if (message) {
        this.data.internal.items[conversationIndex].lastMessageInfo = message;
      }
      this.data.internal.items[conversationIndex].unreadCount = conversation.unreadCount;
      // update conversation list

      if (conversationIndex === 0) return;

      this.data.internal.items.splice(conversationIndex, 1);
      this.data.internal.items.unshift(conversation);
    },
  },
});
