import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { UpdateConversationBody } from "@domain/interfaces/conversation-interface";

export default async function removeMemberUseCase (body: UpdateConversationBody) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationRepository.updateConversation("removeMember", body);
}
