import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { ReactionMessageBody } from "@domain/interfaces/message-interface";

export default function reactionMessageUseCase (body: ReactionMessageBody) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.reactionMessage(body);
}
