import { dateToString, getStartDay } from "@common/utils/date";
import { IMessage, MessageReaction } from "@domain/interfaces/message-interface";
import { useAccountStore } from "@presentation/store/account-store";

import _ from "lodash";

export function groupMessageByDate (messages: IMessage[]): { date: string; messageInDay: IMessage[] }[] {
  return _(messages)
    .groupBy((mess: IMessage) => getStartDay(mess.createdAt))
    .map((messageInDay, date) => ({
      date: getStartDay(date).getTime() === getStartDay(new Date()).getTime() ? "Today" : dateToString(date),
      messageInDay,
    }))
    .valueOf();
}

export const scrollToMessage = (id: string, scrollOptions?: ScrollIntoViewOptions, delay = 200) =>
  setTimeout(() => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView(scrollOptions || { behavior: "auto", inline: "end" });
    }
  }, delay);

export const scrollToConversationPosition = (position?: number, delay = 50) => {
  if (position) {
    setTimeout(() => {
      const element = document.getElementById("conversation");

      if (element) element.scrollBy({ top: position, behavior: "instant" as ScrollBehavior });
    }, delay);
  }
};

export const scrollToEndConversation = (delay = 100) => {
  setTimeout(() => {
    const element = document.getElementById("conversation");

    if (element) {
      const { scrollHeight } = element;

      element.scrollBy({ top: scrollHeight + 1000, behavior: "smooth" as ScrollBehavior });
    }
  }, delay);
};

export function elementInViewport (el: any) {
  let top = el.offsetTop;
  let left = el.offsetLeft;
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  // console.log(top, left, width, height);

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  );
}

export const isOwnerMessage = (message: IMessage) => message.createdByInfo?.username === useAccountStore().loginUser.username;

export const isBotMessage = (message: IMessage) => message.createdByInfo?.type === "bot";

export function onCopyOrCutMessage (userMessage: IMessage, e?: ClipboardEvent) {
  if (isBotMessage(userMessage)) {
    e?.preventDefault();

    return;
  }
  navigator.clipboard.writeText(userMessage.content);
}

export function groupReactionMessageByDate (reactions: MessageReaction[]) {
  return _(reactions)
    .groupBy((reaction: MessageReaction) => reaction.icon)
    .map((users, icon) => ({
      icon,
      users,
    }))
    .valueOf();
}

export const isPinnedMessage = (messageId: string, pinList: IMessage[]) => {
  return !!pinList.find((m) => m.id === messageId);
};
