/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import {
  CreateMessageBody,
  EditMessageResponse,
  GetCountUnreadMessageResponse,
  GetMessageDetailResponse,
  GetMessageListQuery,
  GetMessageListResponse,
  GetPinMessageResponse,
  IMention,
  PinMessageBody,
  PinMessageResponse,
  ReactionMessageBody,
  ReactionMessageResponse,
  SearchMessageQuery,
  SearchMessageResponse,
  UnPinMessageBody
} from "@domain/interfaces/message-interface";

export default abstract class MessageRepository {
  getList (query: GetMessageListQuery): Promise<GetMessageListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  create (body: CreateMessageBody): Promise<GetMessageDetailResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  remove (id: string): Promise<GetMessageDetailResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  search (query: SearchMessageQuery): Promise<SearchMessageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getCountUnreadMessage (): Promise<GetCountUnreadMessageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  editMessage (id: string, values: { content: string; mention: IMention[] }): Promise<EditMessageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  reactionMessage (body: ReactionMessageBody): Promise<ReactionMessageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getPinMessage (conversationId: string): Promise<GetPinMessageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  pinMessage (body: PinMessageBody): Promise<PinMessageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  unpinMessage (body: UnPinMessageBody): Promise<PinMessageResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
