/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { GetUserListResponse, IGetUserListQuery } from "@domain/interfaces/user-interface";

export default abstract class UserRepository {
  getList (query: IGetUserListQuery): Promise<GetUserListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
