import Message from "@domain/entities/message-entity";

import { getFileUrl } from "@common/file";
import { IMention, IMessage, MessageActions, QuoteInfo } from "@domain/interfaces/message-interface";
import { useAccountStore } from "@presentation/store/account-store";

export default function createClientMessageUseCase (data: {
  conversationId: string;
  content: string;
  requestId: string;
  files?: File[];
  action?: MessageActions;
  rootMessage?: IMessage;
  mention?: IMention[];
  quote?: QuoteInfo;
}) {
  const accountStore = useAccountStore();

  return new Message({
    id: "",
    content: data.content,
    conversationId: data.conversationId,
    requestId: new Date().getTime().toString(),
    createdBy: accountStore.loginUser.username,
    createdByInfo: {
      username: accountStore.loginUser.username,
      avatar: accountStore.loginUser.avatar,
      id: accountStore.loginUser.id,
      name: accountStore.loginUser.name,
      type: accountStore.loginUser.type,
      phone: "",
      departmentName: "",
    },
    quote: data.quote,
    replyInfo: data.rootMessage,
    forward: data.rootMessage,
    mention: data.mention || [],
    attachment:
      data.files?.map((file) => {
        return {
          link: getFileUrl(file),
          mimetype: file.type,
          size: file.size,
          originalname: file.name,
          thumbnail: getFileUrl(file),
        };
      }) || [],
  });
}
