import ErrorCode, { API_APP_ERROR, API_SYS_ERROR } from "@common/kernel/error-code";
import { getAccessToken, removeAccessToken } from "@common/storage/get-access-token";
import axios, { AxiosError } from "axios";
import fileDownload from "js-file-download";

export interface IApiResponse {
  status: number;
  code: number;
  message: string;
  data: any;
}

export default class ApiService {
  host: string;
  headers: object;

  constructor () {
    this.host = gConfig.api.host;
    this.headers = {
      product: gConfig.api.product,
      token: gConfig.api.token,
      accessToken: getAccessToken(),
      deviceInfo: {},

      // deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() }),
    };
  }

  private sessionTimeout = (code: number) => {
    if (code === ErrorCode.SESSION_TIMEOUT.code) {
      removeAccessToken();
      window.location.reload();
    }
  };

  async get (path: string, query: any) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.get(url, {
        params: query,
        headers: {
          ...this.headers,
          timestamp: Date.now(),
        },
      });

      if (!response || !response.data) return API_SYS_ERROR as IApiResponse;

      const result = response.data as IApiResponse;

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result as IApiResponse;
      }

      return API_APP_ERROR;
    }
  }

  async post (path: string, body: any, query?: object, sign?:string) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.post(url, body, {
        headers: {
          ...this.headers,
          timestamp: Date.now(),
          sign: sign || "",
        },
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;
      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async put (path: string, body: any, query?: object) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.put(url, body, {
        headers: {
          ...this.headers,
          timestamp: Date.now(),
        },
        params: query,
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async remove (path: string) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.delete(url, {
        headers: {
          ...this.headers,
          timestamp: Date.now(),
        },
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (!axios.isAxiosError(error)) return API_APP_ERROR;

      const err = error as AxiosError;

      const result = err.response?.data as IApiResponse;

      if (result?.code) {
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }

  async save (path: string, method = "POST", fileName: string) {
    const url = `${this.host}${path}`;

    return await axios({
      url,
      method,
      responseType: "blob", // Important
      headers: {
        ...this.headers,
        timestamp: Date.now(),
      },
    })
      .then((response) => {
        fileDownload(response.data, fileName);

        return { isSuccess: true };
      })
      .catch(() => {
        return { isSuccess: false };
      });
  }

  async upload (path: string, data: unknown) {
    try {
      const url = `${this.host}${path}`;
      const response = await axios.post(url, data, {
        headers: {
          ...this.headers,
        },
      });

      if (!response || !response.data) return API_SYS_ERROR;

      const result = response.data as IApiResponse;

      this.sessionTimeout(result.code);

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err = error as AxiosError;
        const result = err.response?.data as IApiResponse;
        this.sessionTimeout(result.code);

        return result;
      }

      return API_APP_ERROR;
    }
  }
}
