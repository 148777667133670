import UserRepository from "@domain/repositories/user-repository";

import { DEFAULT_TIMEOUT } from "@common/constant";
import { GetUserListResponse, IGetUserListQuery } from "@domain/interfaces/user-interface";
import { socket } from "@infrastructure/network/socket";
import { injectable } from "inversify";

@injectable()
export default class UserSocketRepository implements UserRepository {
  getList (query: IGetUserListQuery): Promise<GetUserListResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("user:getList", query, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }
}
