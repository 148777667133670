import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { useMessageStore } from "@presentation/store/message-store";
import { Socket } from "socket.io-client";
import { handleDebug } from "../helper";

export default function onSendReaction (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("sendReaction", (response) => {
    handleDebug("sendReaction", response.data);
    const messageStore = useMessageStore();
    const conversationStore = useConversationStore();

    if (response.data.message.conversationId === conversationStore.selectedConversation.id) {
      messageStore.updateLocalMessageReaction(response.data.message);
    }
  });
}
