import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { UpdateConversationBody } from "@domain/interfaces/conversation-interface";

export default function addConversationMemberUseCase (body: UpdateConversationBody) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return conversationRepository.updateConversation("addMember", body);
}
