import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { IMention } from "@domain/interfaces/message-interface";

export default function editMessageUseCase (id: string, values: { content: string; mention: IMention[] }) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.editMessage(id, values);
}
