export const ACTION = {
  update: "update",
  create: "create",
  delete: "delete",
  submit: "submit",
  view: "view",
  detail: "detail",
  setting: "setting",
  save: "save",
  search: "search",
  select: "select",
  changePage: "changePage",
  upload: "upload",
  query: "query",
  saveContact: "saveContact",
};

export const DEFAULT_LIMIT = 10;

export const DEFAULT_TIMEOUT = 10000;

export const MAX_LIMIT_SEARCH = 20;
