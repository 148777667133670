import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { useMessageStore } from "@presentation/store/message-store";
import { Socket } from "socket.io-client";

export default function onConnected (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("connect", () => {
    useConversationStore().getConversationList();
    useMessageStore().getUnreadMessageCount();

    console.log("Kết nối thành công!");
  });
}
