import { defineStore } from "pinia";

export const useConfigStore = defineStore("config", {
  state: () => ({
    color: {
      colorPrimary: "#52B31F",
      colorBorder: "#D4D8E3",
    },
    theme: { token: { colorPrimary: "#52B31F", colorBorder: "#D4D8E3" } },
  }),
  persist: true,
  actions: {
    setColorPrimary (color: string) {
      this.theme.token.colorPrimary = color;
    },
  },
});
