import repository from "@config/repository";
import MediaRepository from "@domain/repositories/media-repository";
import MessageRepository from "@domain/repositories/message-repository";

import { CreateMessageBody, IMention, MessageActions, RootMessage } from "@domain/interfaces/message-interface";

export default async function createMessageUseCase (data: {
  conversationId: string;
  content: string;
  requestId: string;
  files?: File[];
  action?: MessageActions;
  rootMessage?: RootMessage;
  mention?: IMention[];
  quote?: { id: string; content: string };
}) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);
  const mediaRepository = repository.get<MediaRepository>(MediaRepository);

  const body: CreateMessageBody = {
    content: data.content,
    conversationId: data.conversationId,
    attachment: [],
    requestId: data.requestId,
    action: data.action,
    rootMessage: data.rootMessage,
    mention: data.mention,
    quote: data.quote,
  };

  if (data.files) {
    for (let index = 0; index < data.files.length; index++) {
      const file = data.files[index];
      const result = await mediaRepository.uploadMedia(file);

      body.attachment.push({
        link: result.data.link,
        mimetype: file.type,
        size: file.size,
        originalname: file.name,
        thumbnail: result.data.thumbnail,
      });
    }
  }

  return messageRepository.create(body);
}
