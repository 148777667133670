/* eslint-disable max-len */
export default {
  env: "production",
  host: "https://chat.theadvance.com",
  api: {
    host: "https://chatapi.theadvance.com",
    product: "theAdvanceChatWeb",
    token: "ad4090f0d2fd464b6ec05d0bb88397bc6d8d2427b1c0ffab2ca548e4a68fe2e3",
    key: "THE_ADVANCE_CHAT_2024",
  },
  theAdvanceMedia: {
    host: "https://media.theadvance.com",
    product: "app",
    token: "836478940c58abd80cd54437fa44ad2c806219416c42ca162d7d513828b3e61d",
    maxSizeUpload: 5242880,
  },
};
