import { IMention, IMessage, MessageAttachment, MessageParseMode, MessageReaction, QuoteInfo } from "@domain/interfaces/message-interface";
import { ICreatedByInfo, IUser } from "@domain/interfaces/user-interface";

export default class Message implements IMessage {
  id: string;
  conversationId: string;
  content: string;
  createdAt: Date;
  createdByInfo: ICreatedByInfo;
  createdBy: string;
  forward: IMessage;
  replyInfo: IMessage;
  reactions: MessageReaction[];
  replyMarkup: { inlineKeyboard: [] };
  attachment: MessageAttachment[];
  mention: IMention[];
  isEdited: boolean;
  alowEdited: boolean;
  userSeen: IUser[];
  parseMode: MessageParseMode;
  deepLink: string;
  requestId: string;
  quote: QuoteInfo;

  constructor (data?: Partial<Message>) {
    this.id = data?.id || "";
    this.conversationId = data?.conversationId || "";
    this.reactions = data?.reactions || [];
    this.content = data?.content || "";
    this.attachment = data?.attachment || [];
    this.replyMarkup = data?.replyMarkup || { inlineKeyboard: [] };
    this.createdBy = data?.createdBy || "";
    this.createdAt = data?.createdAt || new Date();
    this.requestId = data?.requestId || "";
    this.mention = data?.mention || [];
    this.createdByInfo = data?.createdByInfo || ({} as IUser);
    this.parseMode = data?.parseMode || "text";
    this.forward = data?.forward || ({} as IMessage);
    this.replyInfo = data?.replyInfo || ({} as IMessage);
    this.isEdited = data?.isEdited || false;
    this.alowEdited = data?.alowEdited || false;
    this.userSeen = data?.userSeen || [];
    this.deepLink = data?.deepLink || "";
    this.quote = data?.quote || ({} as any);
  }
}
