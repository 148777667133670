import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { UnPinMessageBody } from "@domain/interfaces/message-interface";

export default function unpinMessageUseCase (body: UnPinMessageBody) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.unpinMessage(body);
}
