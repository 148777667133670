import Account from "@domain/entities/account-entity";

import { UpdateConversationDetailsBody } from "@domain/interfaces/conversation-details-interface";
import {
  ConversationAction,
  CreateConversationBody,
  GetConversationMemberQuery,
  GetUserConversationQuery,
  UpdateConversationBody
} from "@domain/interfaces/conversation-interface";
import {
  CreateMessageBody,
  GetMessageDetailQuery,
  GetMessageListQuery,
  NewMessageResponse,
  PinMessageBody,
  ReactionMessageBody,
  SendReactionResponse
} from "@domain/interfaces/message-interface";
import { IGetUserListQuery } from "./user-interface";

export interface ISocketResponse {
  status: number;
  code: number;
  message: string;
  data: any;
}

export class SocketResponse<T> implements ISocketResponse {
  status: number;
  code: number;
  message: string;
  data: any;

  constructor (status: number, code: number, message: string, data: T) {
    this.status = status;
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export interface ServerToClientEvents {
  error: (Error: { status: number; code: number; message: string }) => void;
  newMessage: (data: NewMessageResponse) => void;
  sendReaction: (response: SendReactionResponse) => void;

  // user
  loginSuccess: (data: { accessToken: string; user: Account }) => void;
  qrCodeExpired: () => void;
}

export interface ClientToServerEvents {
  // message

  "message:getList": (query: GetMessageListQuery, callback: (data: ISocketResponse) => void) => void;
  "message:create": (data: CreateMessageBody, callback: (data: ISocketResponse) => void) => void;
  "message:remove": (id: string, callback: (data: ISocketResponse) => void) => void;
  "message:reaction": (data: ReactionMessageBody, callback: (data: ISocketResponse) => void) => void;
  "message:search": (query: { search: string; page: number }, callback: (data: ISocketResponse) => void) => void;
  "message:totalUnreadCount": (query: any, callback: (data: ISocketResponse) => void) => void;
  "message:edit": (id: string, body: { content: string }, callback: (data: ISocketResponse) => void) => void;

  "message:pin": (body: PinMessageBody, callback: (data: ISocketResponse) => void) => void;
  "message:removePin": (body: { conversationId: string; messageId: string }, callback: (data: ISocketResponse) => void) => void;
  "message:getPinList": (body: { conversationId: string }, callback: (data: ISocketResponse) => void) => void;
  "message:unpin": (body: { conversationId: string; messageIds: string[] }, callback: (data: ISocketResponse) => void) => void;

  // conversation
  "conversation:getDetail": (query: GetMessageDetailQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:getList": (query: GetUserConversationQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:getMediaList": (query: GetMessageListQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:getFileList": (query: GetMessageListQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:update": (action: ConversationAction, data: UpdateConversationBody, callback: (data: ISocketResponse) => void) => void;
  "conversation:create": (data: CreateConversationBody, callback: (data: ISocketResponse) => void) => void;
  "conversation:search": (query: { search: string }, callback: (data: ISocketResponse) => void) => void;
  "conversation:getMembers": (query: GetConversationMemberQuery, callback: (data: ISocketResponse) => void) => void;
  "conversation:createSaveMessage": (data: any, callback: (data: ISocketResponse) => void) => void;
  // conversation details
  "conversationDetails:update": (data: UpdateConversationDetailsBody, callback: (data: ISocketResponse) => void) => void;

  // user
  "user:getList": (query: IGetUserListQuery, callback: (data: ISocketResponse) => void) => void;
  "user:search": (query: { search: string }, callback: (data: ISocketResponse) => void) => void;
}
