import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { GetMessageListQuery } from "@domain/interfaces/message-interface";

export default function getMessageListUseCase (query: GetMessageListQuery) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.getList(query);
}
