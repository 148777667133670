import MediaRepository from "@domain/repositories/media-repository";
import TheAdvanceMediaService, { TheAdvanceMediaResponse } from "@infrastructure/network/theadvance-media";

import { injectable } from "inversify";

@injectable()
export default class TheAdvanceMediaRepository implements MediaRepository {
  private api = new TheAdvanceMediaService();
  async uploadMedia (file: File): Promise<TheAdvanceMediaResponse> {
    return await this.api.upload("/chat/upload", file);
  }

  async uploadAvatar (file: File): Promise<TheAdvanceMediaResponse> {
    return await this.api.upload("/chat/upload/avatar", file);
  }
}
