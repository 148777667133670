import { routesName } from "@common/route";
import { useAccountStore } from "@presentation/store/account-store";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: `/${routesName.customer}`,
    name: routesName.customer,
    meta: { authRequired: false },
    component: () => import("@interfaces/views/CustomerChatView.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: { authRequired: false },
    component: () => import("@interfaces/views/TestView.vue"),
  },
  {
    path: `/${routesName.login}`,
    name: routesName.login,
    meta: { authRequired: false },
    component: () => import("@interfaces/views/LoginQRCode.vue"),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      if (userStore.isLogin && userStore.loginUser.type === "internal") return next({ name: routesName.home });

      return next();
    },
  },
  {
    path: "/",
    name: routesName.home,
    meta: { authRequired: true },
    component: () => import("@interfaces/views/InternalChatView.vue"),
    props: (route) => ({ query: route.query }),
    beforeEnter: (to, from, next) => {
      const userStore = useAccountStore();

      userStore.loginSuccess(userStore.token, userStore.loginUser);

      if (userStore.isLogin && userStore.loginUser.type === "internal") {
        return next();
      }

      return next(routesName.login);
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
