/* eslint-disable max-len */
const ip = "localhost";
// const ip = "192.168.1.84";

export default {
  env: "local",
  host: `http://${ip}:7604`,
  api: {
    host: `http://${ip}:7603`,
    product: "theAdvanceChatWeb",
    token: "ad4090f0d2fd464b6ec05d0bb88397bc6d8d2427b1c0ffab2ca548e4a68fe2e3",
    key: "THE_ADVANCE_CHAT_2024",
  },
  theAdvanceMedia: {
    host: "https://cm-media-dev.ngocdunggroup.com.vn", // http://localhost:7601
    product: "app",
    token: "836478940c58abd80cd54437fa44ad2c806219416c42ca162d7d513828b3e61d",
    maxSizeUpload: 5242880,
  },
};
