import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { GetUserConversationQuery } from "@domain/interfaces/conversation-interface";

export default async function getConversationListUseCase (query: GetUserConversationQuery) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationRepository.getList(query);
}
