import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { CreateConversationBody } from "@domain/interfaces/conversation-interface";

export default async function createConversationUseCase (body: CreateConversationBody) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationRepository.create(body);
}
